import * as React from "react"
import MainLogo from "../images/main_img_3.png"
import Navbar from "./landing/navbar"
import FadeIn from 'react-fade-in';
import { Helmet } from "react-helmet"

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "sans-serif",
}
const background1Style = {
  backgroundSize: "cover",
  backgroundColor: "black",
  padding: 5,
  minHeight: "100vh",
  minWidth: "100wh",
  backgroundPosition: "0px 0px"
}

const containerStyle = {
  backgroundColor: "black"
}

const whiteFont = {
  color: "#fff"
}

const mainImage = {
  maxHeight: '750px',
}

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Baba Robot</title>
        <link rel="canonical" href="https://babarobot.net" />
        <meta name="description" content="Alt Orient is excited to present 'Baba Robot' at Club Gretchen on July 29th, Berlin; a live electronica concert." />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-GPRG45WDFN"></script>
        <script type="application/ld+json">{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('config', 'G-GPRG45WDFN');
        `}</script>
      </Helmet>
      <div style={background1Style}>
        <Navbar/>

        <div className={"container-fluid"} style={containerStyle}>
          <FadeIn>
            <div className={"row justify-content-center"}>
              <div className={"col-12 text-center mt-3"}>
                <a className={"navbar-brand"} href="https://www.tixforgigs.com/Event/48011?af=6g7vc3pia5pk&saf=kvpf9w8">
                  <img src={MainLogo} style={mainImage} alt="babaRobotLogo" className={"mainImage img-fluid"} />
                </a>
              </div>
            </div>
          </FadeIn>
          <div className={"row justify-content-center"}>
            <div className={"col-2 text-center my-3"}>
              <span style={whiteFont}>© 2023 <a href={"https://www.altorient.com"}><b>Alt Orient</b></a></span>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
